<template>
  <section class="address-config" :class="{ 'scroll-content': !store_setup_import }">
    <div class="md-layout md-gutter address-config__inputs">
      <div class="md-layout-item md-size-50 md-medium-size-66 md-small-size-100">
        <p class="md-title">{{ $translate("shipping_options.tabs.address_message") }}</p>
        <div class="md-layout md-gutter address-config__inputs">
          <div class="md-layout-item md-size-50">
            <md-field
              v-for="field in address_fields.first"
              :key="`project-address-${field}`"
            >
              <label>{{ $translate(`address.${field}`) }}</label>
              <md-input
                :disabled="!can_user_manage_configuration"
                :autocomplete="field"
                :type="field === 'email' ? 'email' : field === 'phone' ? 'number' : 'text'"
                :value="field === 'phone' ? Number(project_address[field]) : project_address[field]"
                @change="event => update_address(field, event.target.value)"
              />
            </md-field>
          </div>
          <div class="md-layout-item md-size-50">
            <md-field
              v-for="field in address_fields.second"
              :key="`project-address-${field}`"
            >
              <label>{{ $translate(`address.${field}`) }}</label>
              <md-input
                :disabled="!can_user_manage_configuration"
                :autocomplete="field"
                type="text"
                :value="project_address[field]"
                @change="event => update_address(field, event.target.value)"
              />
            </md-field>
            <md-field>
              <label for="project-country">{{ $translate("address.country") }}</label>
              <md-select
                v-model="project_country"
                name="project-country"
                id="project-country"
                :disabled="!can_user_manage_configuration"
                md-dense
              >
                <md-option
                  v-for="{ code, name } in countries"
                  :key="`country-${code}`"
                  :value="code"
                >{{ name }}</md-option>
              </md-select>
            </md-field>
          </div>
        </div>
      </div>
      <div class="md-layout-item md-size-25 md-medium-size-33 md-small-size-100">
        <p class="md-title">{{ $translate("address.bank_details") }}</p>
        <p class="md-body-1">{{ $translate("address.bank_detail_message") }}</p>
        <md-field
          v-for="field in bank_details"
          :key="`project-address-${field}`"
        >
          <label>{{ $translate(`address.${field}`) }}</label>
          <md-input
            :disabled="!can_user_manage_configuration"
            type="text"
            :value="project_address[field]"
            @change="event => update_address(field, event.target.value)"
          />
        </md-field>
        <div class="md-body-1">{{ $translate("address.vs_format") }} <b>{{ vs_format }}</b></div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapState, mapMutations } from "vuex"
import { CONFIGURATION_STORE, ADMIN } from "../../constants/others_constants"
import { CONFIGURATION_DATA_UPDATED } from "../../stores/Admin/constants"
import { UPDATE_PROJECT_ADDRESS } from "../../stores/Admin/configuration/constants"
import countries from "../../../../data/countries"

export default {
  props: {
    can_user_manage_configuration: {
      type: Boolean,
      required: true
    },
    store_setup_import: {
      type: Boolean,
      required: false,
      default: true
    },
  },
  data() {
    return {
      address_fields: {
        first: ["name", "email", "phone", "company", "street1"],
        second: ["street2", "city", "zip", "state", "businessID", "vatID"]
      },
      bank_details: ["bank_account", "iban", "swift"],
      countries
    }
  },
  computed: {
    ...mapState(CONFIGURATION_STORE, ["shipping_options", "translations"]),
    project_country: {
      get() { return this.project_address.country },
      set(value) { this.update_address("country", value) }
    },
    project_address() {
      return this.shipping_options.project_address || {}
    },
    vs_format() {
      return `${new Date().getFullYear()}000001 (YYYY000000)`
    }
  },
  methods: {
    ...mapMutations(CONFIGURATION_STORE, {
      update_project_address: UPDATE_PROJECT_ADDRESS
    }),
    ...mapMutations(ADMIN, {
      configuration_data_updated: CONFIGURATION_DATA_UPDATED
    }),
    update_address(key, value) {
      this.update_project_address([key, value])
      this.configuration_data_updated({ types_updated: ["shipping_options"] })
    }
  }
}
</script>

<style lang="scss">
  @use "../../../../styles/_admin-constants" as *;
</style>
